import * as React from "react"
import Hero from "../roaster/hero"
import Oppurtunity from "../roaster/oppurtunity"
import TeamWork from "../roaster/teamwork"
import Join from "../roaster/join"
import Experience from "../roaster/experience"
import Services from "../roaster/services"

import heroimage from "../../assets/images/facilatorBanner.jpg"
import user from "../../assets/images/user2.png"
import f1 from "../../assets/images/icons/shipment.svg"
import f2 from "../../assets/images/icons/delivery.svg"
import f3 from "../../assets/images/icons/exporter.svg"
import f4 from "../../assets/images/icons/cupping.svg"
import f5 from "../../assets/images/icons/milling.svg"
import f6 from "../../assets/images/icons/conduct.svg"
import bulb from "../../assets/images/icons/bulb.svg"
import globe from "../../assets/images/icons/globe.svg"
import thumb from "../../assets/images/icons/thumb.svg"
import marketing from "../../assets/images/icons/market.svg"
import time from "../../assets/images/icons/time.svg"
import Communication from "../../assets/images/icons/invention.svg"
import chart4 from "../../assets/images/icons/chart.svg"

const FacilatatorPage = ({ pageContext: { translate } }) => {
  const dataOppurtunity = [
    {
      icon: globe,
      title: translate.t?.global_movement,
      description: translate.t?.part_global_movement_sustainable,
    },
    {
      icon: thumb,
      title: translate.t?.advantage,
      description: translate.t?.competitive_elevated_traction,
    },
    {
      icon: bulb,
      title: translate.t?.experience,
      description: translate.t?.novel_immersive_exp,
    },
  ]

  const dataTeam = [
    {
      icon: time,
      title: translate.t?.time_investment,
      description: translate.t?.spend_time_understanding_values,
    },
    {
      icon: Communication,
      title: translate.t?.reinvention,
      description: translate.t?.participate_improve_take_charge,
    },
    {
      icon: marketing,
      title: translate.t?.marketing_communication,
      description: translate.t?.spread_word_mission_transparency,
    },
    {
      icon: chart4,
      title: translate.t?.estate_attributes,
      description: translate.t?.roasters_adopt_onboard,
    },
  ]

  const dataService = [
    {
      title: translate.t?.sample_shipment,
      description: translate.t?.send_roasters_buyers_gc,
      img: f1,
    },
    {
      title: translate.t?.import_and_delivery,
      description: translate.t?.gc_producers_cooperatives,
      img: f2,
    },
    {
      title: translate.t?.exporter,
      description: translate.t?.ship_gc_producers_cooperatives,
      img: f3,
    },
    {
      title: translate.t?.cupping,
      description: translate.t?.score_grade_gc,
      img: f4,
    },
    {
      title: translate.t?.milling,
      description: translate.t?.dry_wet_transform,
      img: f5,
    },
    {
      title: translate.t?.coc_audit_public,
      description: translate.t?.make_sure_stakeholders,
      img: f6,
    },
  ]

  return (
    <>
      {
        <div className="facilitator">
          <div className="roaster_page">
            <div className="content">
              <Hero
                pageContext={{ translate }}
                pagename={translate.t?.eraofwe_for_fc}
                title={translate.t?.streamline_services}
                description={translate.t?.forge_ltr}
                btntext={translate.t?.book_a_demo}
                img={heroimage}
                btnLink=""
                model="contact"
              />
              <Oppurtunity
                pageContext={{ translate }}
                heading={translate.t?.new_opp_hospitalities_alike}
                data={dataOppurtunity}
              />
              {/* <Experience
            title={translate.t?.exp_fc_love_talk}
            btnText={translate.t?.view_our_community}
            btnLink={`${translate.currentLang ? '/' + translate.currentLang + '/' : '/'}community/`}
            img={user}
            name="Julia Sanchez"
            designation="Purchasing director"
            pagetitle="Blue Roasters"
            description={`${translate.t?.higher_success_rate_relationship} ${translate.t?.reduction_support_tickets}`}
          /> */}
              <div className="services-new facilatators">
                <Services
                pageContext={{ translate }}
                  title={translate.t?.setup_services_manage_sr}
                  description={translate.t?.choose_services_offer_platform}
                  data={dataService}
                  btnText={translate.t?.contact_us}
                  btnLink={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }contact/`}
                />
              </div>
              <div className="facilitator estate">
                <TeamWork
                pageContext={{ translate }}
                  heading={translate.t?.great_teamwork_commitment}
                  btnText={translate.t?.download_whitepaper}
                  btnLink=""
                  modal="download"
                  data={dataTeam}
                />
              </div>
              <div className="joiner-second">
                <Join pageContext={{ translate }}/>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default FacilatatorPage
