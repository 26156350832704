import React, { useState } from "react"
import { Container, Row, Col, Button, Modal } from "react-bootstrap"
import { Link } from "gatsby"
import ModalCloseIcon from "../../assets/images/crossGrey.png"

const ModelInside = ({
  handleClose,
  show,
  img,
  title1,
  description1,
  details,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
          className="servicesModal"
        >
          <div className="modal-header">
            <button type="button" onClick={handleClose} className="close">
              <span aria-hidden="true">
                <img src={ModalCloseIcon} alt="Close" />
              </span>
              <span className="sr-only">{translate.t?.close}</span>
            </button>
          </div>
          <Modal.Body>
            <Container fluid className="p-0">
              <Row>
                <Col className="dataIcon">
                  <img src={img} alt="Data Icon" />
                </Col>
                <Col className="modalDescription">
                  <h3 className="modalTitle">{title1}</h3>
                  <p>{description1}</p>
                  {details && (
                    <ul>
                      {details.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                  {!details && (
                    <ul>
                      <li>{translate.t?.higher_success_rate_relationship}</li>
                      <li>{translate.t?.higher_success_rate}</li>
                    </ul>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      }
    </>
  )
}

const Services = ({
  title,
  description,
  btnText,
  btnLink,
  data,
  details,
  pageContext: { translate },
}) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [title1, setTitle] = useState("")
  const [img, setImg] = useState("")
  const [description1, setDescription] = useState("")
  const [detailsData, setDetailsData] = useState(null)

  return (
    <>
      {
        <>
          <ModelInside
            img={img}
            title1={title1}
            description1={description1}
            details={detailsData}
            show={show}
            handleClose={handleClose}
            pageContext={{ translate }}
          />
          <Container>
            <Row className="section-head">
              <Col md={8} sm={8} xs={12}>
                <h2 className="mb-3">{title}</h2>
                <p>{description}</p>
              </Col>
              <Col md={4} sm={4} xs={12} className="text-right contact-btn">
                <Link to={btnLink}>
                  <button className="btndefault greenbtn">{btnText}</button>
                </Link>
              </Col>
            </Row>
            <Row className="service-row">
              {data?.map((content, counter) => (
                <Col key={counter} lg={4} sm={6} xs={6} className="box-wrapper">
                  <div className="box d-flex flex-column justify-content-between">
                    <div>
                      <img src={content.img} alt="Services Content" />
                      <h3>{content.title}</h3>
                      <p>{content.description}</p>
                    </div>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setImg(content.img)
                        setTitle(content.title)
                        setDescription(content.description)
                        setDetailsData(content.details)
                        handleShow()
                      }}
                    >
                      <span>{translate.t?.learn_more_public}</span>
                      <span className="arrowIcon" />
                    </Button>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      }
    </>
  )
}

export default Services
